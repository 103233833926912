import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noise',
  templateUrl: './noise.component.html',
  styleUrls: ['./noise.component.scss']
})
export class NoiseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
