import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Receiver } from '../../core/models/receiver';
import { ReceiverService } from '../../core/services/receiver.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-receiver',
  templateUrl: './receiver.component.html',
  styleUrls: ['./receiver.component.scss']
})
export class ReceiverComponent extends CrudeListComponent<Receiver> implements OnInit {

  private receiverForm: FormGroup;
  private receiverState: Receiver;

  private isEnabled: boolean;

  constructor(private receiverService: ReceiverService) {
    super(receiverService);
  }

  ngOnInit() {
    this.setupFormGroup();
    this.setupFormEvents();

    this.getAll();
  }

  onEntitiesFetched() {
    if (!this.entities.length) {
      return;
    }

    this.applyNewStateToForm(this.entities[0]);
  }

  onEntityAdded(response: Receiver) {
    this.applyNewStateToForm(response);
  }

  submit() {
    this.applyFormToState();
    console.warn('Receiver', this.receiverState);
    this.update(this.receiverState);
  }

  private setupFormGroup() {
    this.receiverForm = new FormGroup({
      enabled: new FormControl(false)
    });
  }

  private setupFormEvents() {
    this.receiverForm.get('enabled').valueChanges.subscribe(value => {
      this.isEnabled = value;
    });
  }

  private applyNewStateToForm(receiver: Receiver) {
    this.receiverState = receiver;

    this.receiverForm.get('enabled').setValue(receiver.enabled);
  }

  private applyFormToState() {
    this.receiverState.enabled = this.receiverForm.get('enabled').value;
  }

  private createNewReceiver() {
    const receiver: Receiver = new Receiver();
    receiver.id = -1;
    receiver.enabled = true;
    receiver.version = 0;

    this.add(receiver);
  }
}
