import { Component, OnInit } from '@angular/core';

import { Circuit } from '../../core/models/circuit';
import { CircuitService } from '../../core/services/circuit.service';
import { CrudeSearchComponent } from '../../core/base/component/crude-search.component';

@Component({
  selector: 'app-circuit-search',
  templateUrl: './circuit-search.component.html',
  styleUrls: ['./circuit-search.component.scss']
})
export class CircuitSearchComponent extends CrudeSearchComponent<Circuit> implements OnInit {

  constructor(circuitService: CircuitService) {
    super(circuitService);
  }

  ngOnInit() {
    this.entities$ = this.searchInit();
  }
}
