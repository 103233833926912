import { Muzak } from '../models/muzak';

export const MUZAKS: Muzak[] = [
  {
          id: 0,
          name: 'The First Noisy Kube',
          title: 'The First Noisy Kube',
          description: 'The First Noisy Kube',
          link: 'http://www.mediafire.com/listen/3nll7175v7esqj8/The%20First%20Noisy%20Kube.mp3',
          soundcloud: '',
          soundcloudLink: '',
          images: [],
          date: '14-10-2007',
          duration: '9:50 (min)',
          size: '11,808 (Kb)'
  },

  {
          id: 1,
          name: 'The Second Noisy Kube',
          title: 'The Second Noisy Kube',
          description: 'The Second Noisy Kube',
          link: 'http://www.mediafire.com/listen/16q7ajr4fh55pv9/The%20Second%20Noisy%20Kube.mp3',
          soundcloud: '',
          soundcloudLink: '',
          images: [],
          date: '12-6-2008',
          duration: '48:03 (min)',
          size: '46,141 (Kb)'
  },

  {
          id: 2,
          name: 'Like the First Emotional Conflict',
          title: 'Like the First Emotional Conflict',
          description: 'Like the First Emotional Conflict',
          link: 'http://www.mediafire.com/listen/u7yf6v4pdfdloou/Like%20the%20First%20Emotional%20Conflict.mp3',
          soundcloud: '57893233',
          soundcloudLink: 'http://soundcloud.com/thefirstkube/like-the-first-emotional',
          images: [],
          date: '17-1-2009',
          duration: '24:24 (min)',
          size: '23,424 (Kb)'
  },

  {
          id: 3,
          name: 'Scream of the DayLight, Cloud Abuse',
          title: 'Scream of the DayLight, Cloud Abuse',
          description: 'Scream of the DayLight, Cloud Abuse',
          link: 'http://www.mediafire.com/listen/la130oi2a2q9dlj/Scream%20of%20the%20DayLight%2C%20Cloud%20Abuse.mp3',
          soundcloud: '57891629',
          soundcloudLink: 'http://soundcloud.com/thefirstkube/scream-of-the-daylight-cloud',
          images: [],
          date: '18-1-2009',
          duration: '15:13 (min)',
          size: '12,425 (Kb)'
  },

  {
          id: 4,
          name: 'She and Her Neverending Sadness',
          title: 'She and Her Neverending Sadness',
          description: 'She and Her Neverending Sadness',
          link: 'http://www.mediafire.com/listen/bqnbcby3p79q0k9/She%20and%20Her%20Neverending%20Sadness.mp3',
          soundcloud: '57893957',
          soundcloudLink: 'http://soundcloud.com/thefirstkube/she-and-her-neverending',
          images: [],
          date: '31-5-2009',
          duration: '17:15 (min)',
          size: '12,425 (Kb)'
  },

  {
          id: 5,
          name: 'Painfull Wake Up',
          title: 'Painfull Wake Up',
          description: 'Painfull Wake Up',
          link: 'http://www.mediafire.com/listen/hkzbc12k548sphj/Painfull%20Wake%20Up.mp3',
          soundcloud: '57891939',
          soundcloudLink: 'http://soundcloud.com/thefirstkube/painfull-wake-up',
          images: [],
          date: '31-5-2009',
          duration: '07:52 (min)',
          size: '5,670 (Kb)'
  },

  {
          id: 6,
          name: 'Unique',
          title: 'Unique',
          description: 'Unique',
          link: 'http://www.mediafire.com/listen/kh27kubyd2sgnls/Unique.mp3',
          soundcloud: '57890463',
          soundcloudLink: 'http://soundcloud.com/thefirstkube/unique',
          images: [],
          date: '30-8-2009',
          duration: '11:54 (min)',
          size: '8,372 (Kb)'
  },

  {
          id: 7,
          name: 'The Analog Way of Life',
          title: 'The Analog Way of Life',
          description: 'The Analog Way of Life',
          link: 'http://www.mediafire.com/listen/4w8cl3nz2fdn553/The%20Analog%20Way%20Of%20Life.mp3',
          soundcloud: '122618771',
          soundcloudLink: 'https://soundcloud.com/thefirstkube/the-analog-way-of-life',
          images: [],
          date: '23-11-2013',
          duration: '24:17 (min)',
          size: '22,760 (Kb)'
  },
];
