<h2>
  radio station
</h2>

<section *ngIf="entity">

  {{entity | json}}

  <button (click)="goBack()">go back</button>

  <form [formGroup]="radioStationForm" (ngSubmit)="submit()">

    <section class="row">
      <section class="col4">
        <label>
          {{entity.radioStation.title}}
        </label>
      </section>
    </section>

    <section class="row">
      <section class="col4">
        <p>
          {{entity.radioStation.description}}
        </p>
      </section>
    </section>

  </form>

</section>
