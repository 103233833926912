import { Injectable } from '@angular/core';

import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private messageService: MessageService) { }

  /** Log a message with the MessageService */
  log(sender: string, message: string) {
    this.messageService.add(`${sender}: ${message}`);
  }
}
