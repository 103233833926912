import { TransmitterRadioStation } from '../models/transmitter-radio-station';

export const TRANSMITTER_RADIO_STATIONS: TransmitterRadioStation[] = [
    {
        id: 1000,
        orderId: 0,
        radioStation: {
            description: 'Experiments and all that jazz..',
            enabled: true,
            id: 1000,
            name: 'The First Kube :: Radiostation',
            title: '..dance dance dance dance dance to the radio..',
            version: 0
        },
        version: 0
    }
];
