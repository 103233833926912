import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CircuitsComponent } from './circuits/circuits.component';
import { CircuitDetailComponent } from './circuit-detail/circuit-detail.component';

import { AppRoutingModule } from '../app-routing.module';
import { MuzaksComponent } from './muzaks/muzaks.component';
import { MuzakDetailComponent } from './muzak-detail/muzak-detail.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ContactComponent } from './contact/contact.component';
import { CircuitSearchComponent } from './circuit-search/circuit-search.component';
import { NoiseComponent } from './noise/noise.component';
import { LinksComponent } from './links/links.component';
import { MyComponent } from './my/my.component';
import { ReceiverComponent } from './receiver/receiver.component';
import { TransmitterComponent } from './transmitter/transmitter.component';
import { ReceiverRadioStationsComponent } from './receiver-radio-stations/receiver-radio-stations.component';
import { TransmitterRadioStationsComponent } from './transmitter-radio-stations/transmitter-radio-stations.component';
import { ReceiverRadioStationDetailComponent } from './receiver-radio-station-detail/receiver-radio-station-detail.component';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule
  ],
  declarations: [
    DashboardComponent,
    CircuitsComponent,
    CircuitDetailComponent,
    CircuitSearchComponent,
    MuzaksComponent,
    MuzakDetailComponent,
    ApplicationsComponent,
    ContactComponent,
    NoiseComponent,
    LinksComponent,
    MyComponent,
    ReceiverComponent,
    TransmitterComponent,
    ReceiverRadioStationsComponent,
    TransmitterRadioStationsComponent,
    ReceiverRadioStationDetailComponent,
    ProfileComponent
  ],
  exports: [
    DashboardComponent,
    CircuitsComponent,
    CircuitDetailComponent,
    CircuitSearchComponent,
    MuzaksComponent,
    MuzakDetailComponent,
    ApplicationsComponent,
    ContactComponent
  ]
})
export class FeatureModule { }
