import { BaseModel } from '../../core/base/model/base-model';

export class Circuit extends BaseModel {
  name: string;
  title: string;
  description: string;
  images: string[];
  schematics: string[];
  files: string[];
  date: string;
  more: string;
  source: string;
}
