import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Circuit } from '../../core/models/circuit';
import { CircuitService } from '../../core/services/circuit.service';

import { CrudeDetailComponent } from '../../core/base/component/crude-detail.component';


@Component({
  selector: 'app-circuit-detail',
  templateUrl: './circuit-detail.component.html',
  styleUrls: ['./circuit-detail.component.scss']
})
export class CircuitDetailComponent extends CrudeDetailComponent<Circuit> implements OnInit {

  @Input() circuit: Circuit;

  constructor(
    route: ActivatedRoute,
    circuitService: CircuitService,
    location: Location) {

    super(route, circuitService, location);

    this.entity = this.circuit;
  }

  ngOnInit() {
    this.get();
  }
}
