<h2>Circuits</h2>

<!-- <app-circuit-search></app-circuit-search> -->

<article class="circuits" *ngFor="let circuit of entities">
  <a name="#{{circuit.title}}"></a><h3>{{circuit.title}}</h3>
  <p>
    <img class="list-preview" src="{{getFirstImage(circuit)}}" alt="{{circuit.title}}" width="100" height="100" />
    <br />
    <span class="more">Build: <time>{{circuit.date}}</time></span>
    <br />
    <a class="more" routerLink="/circuit/{{circuit.id}}">More</a>
    <a class="more" href="{{circuit.source}}" target="_blank">Source</a>
  </p>
</article>
