import { Component, OnInit } from '@angular/core';

import { Application } from '../../core/models/application';
import { ApplicationService } from '../../core/services/application.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent extends CrudeListComponent<Application> implements OnInit {

  constructor(private applicationService: ApplicationService) {
    super(applicationService);
  }

  ngOnInit() {
    this.getAll();
  }
}
