<h2>Applications</h2>

<app-github-badge></app-github-badge>

<article class="applications list-item" *ngFor="let application of entities">
  <a name="#{{application.title}}"></a>
  
  <h3>
    {{application.title}}
  </h3>

  <section class="list-item-details">
    <img *ngIf="application.image" class="list-preview" src="{{application.image}}" alt="{{application.title}}" width="100" height="100" />
    <br />

    <section class="summary">
      <p>{{application.summary}}</p>
    </section>
    
    <span class="more">Year: <time>{{application.date}}</time></span>
    <br />
    
    <span class="more">Version: {{application.version}}</span>
    <br />

    <br />

    <!-- <a class="more" routerLink="/application/{{application.id}}">More</a> -->
    <a *ngIf="application.source" class="more {{application.sourceType}}" href="{{application.source}}" target="_blank">More</a>
    <a class="more" href="mailto:info@thefirstkube.net">Bug</a>
  </section>
</article>
