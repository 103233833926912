import { Picture } from './../models/picture';

export const DASHBOARD_PICTURES: Picture[] = [
  {
    id: 1,
    name: 'Seagull',
    title: 'Seagull',
    description: '.. Summer, Sun and Arabian Nights .. [14-7-2014]',
    href: 'https://assets.lomography.com/f3/32b044a93c34b097523872791e350da48bde6b.jpg?auth=868504b523eb1bf5f391eeeae2b8d068b625888f',
    enabled: true
  },

  {
    id: 2,
    name: 'First swim',
    title: 'First swim',
    description: '.. Summer, Sun and Arabian Nights .. [14-7-2014]',
    href: 'https://assets.lomography.com/ae/703d6d90b627e8805039cf1708b611e6b9705f.jpg?auth=5703afec5ab7fdca7733c4397937cb81c5a5c493',
    enabled: true
  },

  {
    id: 3,
    name: '.. Dawn talkings ..',
    title: '.. Dawn talkings ..',
    description: '.. Summer, Sun and Arabian Nights .. [14-7-2014]',
    href: 'https://assets.lomography.com/f3/5f868b85133b089208706a25df8c0b2a3bc57f.jpg?auth=da8fefb862f9dea2c4bfce2a95d06110ed4c93e5',
    enabled: true
  },

  {
    id: 4,
    name: 'Slow boat to Thassos..',
    title: 'Slow boat to Thassos..',
    description: '.. X-Pro, Underwater Birthday .. [14-7-2014]',
    href: 'https://assets.lomography.com/2b/8533bd5af26659ebcfc9f30d2a4b4401f1e6df.jpg?auth=acf3bf929b656f0c6da9d669dae4c50af8105517',
    enabled: true
  },

  {
    id: 5,
    name: 'Slow boat to Thassos..',
    title: 'Slow boat to Thassos..',
    description: '.. X-Pro, Underwater Birthday .. [14-7-2014]',
    href: 'https://assets.lomography.com/13/4c00cf42ee07844346e8a9705bd370aa2454d7.jpg?auth=22af6f7422e4af975d3d251bd50125f89beba9d3',
    enabled: true
  },

  {
    id: 6,
    name: 'A sun that never sets..',
    title: 'A sun that never sets..',
    description: '.. X-Pro, Underwater Birthday .. [14-7-2014]',
    href: 'https://assets.lomography.com/45/4e7687027985ba246f0e3e9d9b94a77dff18c1.jpg?auth=8d18c77f530507cba70502cb07861a4092a6560a',
    enabled: true
  },

  {
    id: 7,
    name: 'Sailing to Kavala',
    title: 'Sailing to Kavala',
    description: '.. X-Pro, Underwater Birthday .. [14-7-2014]',
    href: 'https://assets.lomography.com/32/80ac529543753e71341a38cd1b0341aa4b5fe2.jpg?auth=45d9c9e5a3e299cb973fc9e484f89c3e29af5601',
    enabled: true
  },


  {
    id: 8,
    name: 'Slowdive',
    title: 'Slowdive',
    description: '.. Slowdive .. [08-08-2015]',
    href: 'https://assets.lomography.com/f0/375b0ad2632d2e2d4c2ba9ec09cbc77d19872b.jpg?auth=543c92a867b569c6d31ef1367fe7455e24016ab3',
    enabled: true
  },
];
