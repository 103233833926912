import { Circuit } from '../models/circuit';

export const CIRCUITS: Circuit[] = [
  {
    id: 0,
    name: '',
    title: 'Pink Noise Generator',
    description: `For audio testing, a pink noise source is an invaluable tool.
    It is essentially a flat frequency response noise source, and will quickly
    show any anomalies in speaker systems, room acoustics and crossover networks.
    White noise (the sound you hear when a TV is tuned to a non-existent station)
    has a frequency characteristic which raises the power level by 3dB with each
    increasing octave, and is not suitable for response testing
    (and will probably blow your tweeters as well).
    By combining a 3dB / octave filter and a white noise source, we can get a very
    good approximation to 'perfect' pink noise, where the power in the octave
    (for example) 40 to 80Hz is exactly the same as in the octave 10kHz to 20kHz.
    Figure 1 shows the circuit diagram for the filter, which uses the 1458 dual
    op-amp for economy. There is no point using a low-noise device in something
    which is specifically designed to make noise, so this op-amp is fine for the purpose.
    The BC548 transistor is connected so its emitter-base junction is reverse biased,
    which creates a nice noisy zener diode. With the values shown, the average noise
    output is about 30mV (broadband). The transistor zener voltage is a bit iffy,
    mine runs at about 9V, but it could be anywhere from 5V up to 10V. In some cases,
    you may find that the transistor is not noisy enough, so try a few until you get
    one that makes lots of noise. The first op-amp stage acts as an amplifier / buffer,
    providing a very high input impedance (so as not to load the noise source),
    and having a gain of 11 (20.8dB). The DC voltage at the output of the buffer s
    hould be the same (or very close to) that at the transistor zener.
    The positive battery supply connects to pin 8 of the op-amp, and the negative
    to pin 4 - don't mix up the battery polarity, or the op-amp will die.
    The 10uF capacitors marked 'NP' are bipolar (non polarised) electrolytics.
    Although film caps can be used, they will contribute nothing but cost to the final project.
    Non-polarised caps are needed because of an unpredictable polarity for C4 and
    no little or no DC across C8. The second stage is a 3dB / octave filter,
    which is quite linear across the frequency band 20Hz to 20kHz.
    This converts the white noise into pink noise, having equal energy in
    all 10 octaves of the audio band. Because of the comparatively high zener
    voltage of the transistor, the supply voltage needs to be somewhat higher - 2
    standard size 9V alkaline batteries in series (18V) should run the unit for far
    longer than you will ever want to listen to it. Because of the limited capacity
    of the 9V batteries, no indicator LED has been included, as this would draw more
    current than the rest of the circuit. The power switch must be a Double Pole,
    Single Throw (DPST) type, as both batteries must be disconnected. The centre-tap
    of the batteries is the earth (or ground) for the unit. All earth points
    (the upside down triangles) must be connected together. The entire circuit can
    be laid out on a piece of prototype board, and mounted in a suitable plastic or
    metal box. No special precautions are needed, other than ensuring that polarised
    components (transistor, op-amp, and electrolytic capacitors) are connected
    the right way 'round. Values of components are not critical, so standard
    tolerance components should be fine throughout. The use of 1% metal film
    resistors to keep noise to a minimum is not required in this circuit!
    The transistor can actually be any small signal type you have handy,
    and so can the dual opamp (or a pair of single opamps can be used -
    note that their pinouts are completely different). If you have an
    oscilloscope or can get access to one, check that the noise output
    is not clipping (you won't be able to hear it, but if it clips,
    the energy spectrum will be modified). There is no easy way to check
    without a 'scope, and the noise output from transistors used in this way
    tends to vary somewhat. If clipping is observed (or you suspect it),
    increase the value of R3 or R4 (both 10k). Doubling the value (of one
    or the other - not both) will reduce the output by half. There are digital
    'pseudeo-random' noise generators available, but I don't like them, since
    they have a cycle which eventually repeats and this is very audible.
    By contrast, the unit described is completely random, as only analogue
    can be Figure 2 shows the 3dB/ Octave response obtained.
    It is not perfect (I have never seen one that was), but it is
    more than close enough for all but the most exacting of requirements.
    The low bass rolloff is created by the output cap and C7 (it is actually
    slightly greater than shown). The error is typically less than 1dB over
    the audio band. Using A Noise Generator Connect the generator to your preamp,
    and slowly advance the level control until the sound level is at about the
    level of normal speech (about 65dB). Carefully listen for any 'tonality'
    in the sound, such as a low hum, or a point where the signal seems to
    disappear (sometimes referred to as a 'suckout'), or anything which does
    not sound like pure noise. This will probably take a little practice -
    if you have a graphic equaliser handy, this is a great way to introduce
      peaks and dips to hear what they sound like. Try listening through a
    good set of headphones, and compare the result with the speakers and room
    acoustics, you might be surprised at the result. I once read a story
    where an engineer was trying to find out where the hum in his noise
    generator was coming from. It turned out that the noise generator had
    no hum at all, but he was hearing the bass resonance from a badly
    designed loudspeaker - you can get results from these little guys!
    Reference : Electronics Today International, November 1981 - Audio White
    Noise Generator Employs Digital Technique (3dB/octave filter component values)`,
    images: [
      'assets/img/circuits/pinknoise.jpg'
    ],
    schematics: [
      'assets/img/circuits/Pink Noise Generator.bmp',
      'assets/img/circuits/sch02.gif'
    ],
    files: [],
    date: '07-02-2007',
    more: '?id=8',
    source: 'http://sound.westhost.com/project11.htm'
  },

  {
    id: 1,
    name: '',
    title: 'Noise Joystick Synth',
    description: '',
    images: [
      'assets/img/circuits/JoystickNoiseSynth.jpg'
    ],
    schematics: [],
    files: [],
    date: '16-02-2007',
    more: '?id=8',
    source: 'http://www.notbreathing.com'
  },

  {
    id: 2,
    name: '',
    title: 'Noise Insect Synth',
    description: `Horrible six photocell insect synth light controls sequence,
    pitch, filtering and cross modulation circuit bent into existance`,
    images: [
      'assets/img/circuits/InsectSynth.jpg'
    ],
    schematics: [],
    files: [],
    date: '16-02-2007',
    more: '?id=8',
    source: 'http://www.notbreathing.com'
  },

  {
    id: 3,
    name: '',
    title: 'N0SS',
    description: '',
    images: [
      'assets/img/circuits/N0SS.jpg'
    ],
    schematics: [],
    files: [
      'assets/img/circuits/noise_source.pdf'
    ],
    date: '19-04-2007',
    more: '?id=8',
    source: 'http://www.n0ss.net/'
  },

  {
    id: 4,
    name: 'Multi Noise',
    title: 'Multi Noise and Random Gate/Trigger Generator',
    description: `Board layout is good I have made boards from it and it works.
    All the noise you could want AND MORE. This circuit provides: White Noise,
    Digital Noise, Roaring Noise, Grainy Noise, AND... Random Gates and
    Triggers. &lt;P&gt;Q2 is a small signal NPN transistor (2N2712 or 2N3904
    selected for best noise). R1 limits the current through the reversed
    biased e-b junction but since the voltage drop across it is 24 volts
    the junction breaks down and makes noise. The white noise that is
    developed across the e-b junction is capacitively coupled into OP-Amp
    IC1-B where a gain of between 470 to as much as you want is applied.
    You need to insure that the level of noise at pin 7 of IC1-B is about
    5 to 6 volts AVERAGE and 8 to 9 volts MAXIMUM PEAK to PEAK. By doing
    that everything else should work. IC1-A is used as a comparator comparing
    the average value of the noise to the noise itself. This results in rail
    to rail noise appearing at pin 1 of iC1-A. You can square the digital
    noise up a bit by adding a small (i.e. 5 to 10 pF) cap between IC1 pins
    1 and 3. This noise is buffered and filtered a bit by IC2-A. IC2-A's
    output sounds like Niagra Falls. IC1-B's output is buffered and gained
    up a bit by IC1-C. The output of IC1-C is fed into the window comparator
    made up by IC1-D, IC2-C and associated components. R20 adjusts the window.
    When the noise appearing on IC1-D is higher than the voltage applied to
    its pin 13 then IC1-D's output goes high. When the noise appearing on
    IC2-C is lower than the voltage applied to its pin 9 then IC2-C's
    output goes low. Diodes CR4 and CR5 pass the high excursions of IC1-D
    and the low excursions of IC2-C and drop them on R23. By adjusting R20
    you can go from a few clicks to roaring noise appearing at the output
    of IC2-D. The high excursions of IC1-D are also fed via CR2 to the
    clock input of IC3-A (D Flip Flop). The two flip flops are wired so
    that they form a divide by four counter. Thus the random clocks
    appearing at pin 3 of IC3-A cause pin 13 of IC3-B to go high and low
    randomly (i.e. without a regular frequency). These high and low
    excursions are fed out as Random Gates and the front end of the
    gate pulses is sent out over the Trigger output. The Graininess
    control (window comparator range adjuster) affects the output of gates
    and triggers. When the Grainy noise output is hardly clicking you will
    get slow random gates and triggers. When the Grainy noise sounds like
    a downpour then you will get fast random gates and triggers. When the
    gates or triggers are occuring fast enough they sound like noise (again).
    I used LF444s on my board which are a lower current direct replacement
    for the TL084s. &lt;P&gt;For the reversed biased e-b junction you can
      try any old small signal NPN you have lying around but the 2N3904
    or 2N2712 will definitely work. YOU WILL DEFINITELY WANT TO ADD
    VOLTAGE DIVIDING POTS FOR THE OUTPUTS. They are pretty beefy
    (+ and - 10 to 12 volts). There you have it now go have some random
    fun. * RN - Roaring Noise out * DN - Digital Noise out * WN - White
    Noise out &lt;/b&gt;&lt;br /&gt; * &lt;b&gt;GN - Grainy Noise out
     * Tr - Trigger out * Gt - Gate out`,
    images: [
      'assets/img/circuits/MultyNoise.jpg',
      'assets/img/circuits/multynoise/img0010.jpg',
      'assets/img/circuits/multynoise/img0011.jpg',
      'assets/img/circuits/multynoise/img0012.jpg'
    ],
    schematics: [
      'assets/img/circuits/multynoise/pcb01.gif',
      'assets/img/circuits/multynoise/pcb02.gif',
      'assets/img/circuits/multynoise/sch01.gif',
    ],
    files: [],
    date: '14-06-2007',
    more: '?id=8',
    source: 'http://www.musicfromouterspace.com'
  },

  {
    id: 5,
    name: 'Pink Enough For Me',
    title: 'Pink Enough For Me',
    description: `Here is a circuit board for this. I made it skinny so I
    could fit it onto another board I was making that had some room left.
    When I was building this I noticed that I had to increase the gain of
    the circuit so I reduced the value of R3 to 500 ohms. I also buffered
    the outputs as you can see from the board. Just put pink noise into one
    side of the buffer and white noise into the other and voila you have 4
    noise sources (more than you will probably ever need). The buffer is
    not shown in the schematic but it is quite simple in that its just
    four non-inverting unity gain buffers with the non-inverting inputs
    of two pairs tied together.`,
    images: [
      'assets/img/circuits/PinkEnoughNoise.jpg',
      'assets/img/circuits/pink/img0091.jpg',
      'assets/img/circuits/pink/img0092.jpg',
      'assets/img/circuits/pink/img0093.jpg',
      'assets/img/circuits/pink/img0094.jpg'
    ],
    schematics: [
      'assets/img/circuits/pink/pcb01.gif',
      'assets/img/circuits/pink/pcb02.gif',
      'assets/img/circuits/pink/sch01.gif'
    ],
    files: [],
    date: '29-12-2007',
    more: '?id=8',
    source: 'http://www.musicfromouterspace.com'
  },

  {
    id: 6,
    name: 'Tube Sound Fuzz',
    title: 'Tube Sound Fuzz',
    description: '',
    images: [
      'assets/img/circuits/TubeSoundFuzz.jpg',
      'assets/img/circuits/tubesoundfuzz/img0097.jpg',
      'assets/img/circuits/tubesoundfuzz/img0098.jpg',
      'assets/img/circuits/tubesoundfuzz/img0100.jpg'
    ],
    schematics: [
      'assets/img/circuits/tubesoundfuzz/tube sound fuzz.gif'
    ],
    files: [],
    date: '10-10-2009',
    more: '?id=8',
    source: 'http://www.muzique.com/schem/index.html'
  }
];
