import { InMemoryDbService } from 'angular-in-memory-web-api';
import { RequestInfoUtilities } from 'angular-in-memory-web-api';
import { ParsedRequestUrl } from 'angular-in-memory-web-api';

import { CIRCUITS } from './core/mocks/mock-circuits';
import { MUZAKS } from './core/mocks/mock-muzaks';
import { APPLICATIONS } from './core/mocks/mock-applications';
import { LINKS } from './core/mocks/mock-links';
import { AUTH_RESULTS } from './core/mocks/mock-auth';
import { DASHBOARD_PICTURES } from './core/mocks/mock-dashboard-pictures';
import { RECEIVER } from './core/mocks/mock-receiver';
import { RECEIVER_RADIO_STATIONS } from './core/mocks/mock-receiver-radio-stations';
import { TRANSMITTER } from './core/mocks/mock-transmitter';
import { TRANSMITTER_RADIO_STATIONS } from './core/mocks/mock-transmitter-radio-stations';

export class InMemoryDataService implements InMemoryDbService {
  createDb() {

    const circuits = CIRCUITS;
    const muzaks = MUZAKS;
    const applications = APPLICATIONS;
    const links = LINKS;
    const authentication = AUTH_RESULTS;
    const dashboardpictures = DASHBOARD_PICTURES;
    const receiver = RECEIVER;
    const receiverradiostations = RECEIVER_RADIO_STATIONS;
    const transmitterradiostations = TRANSMITTER_RADIO_STATIONS;
    const transmitter = TRANSMITTER;

    return {
      circuits,
      muzaks,
      applications,
      links,
      dashboardpictures,
      // authentication
      receiver,
      receiverradiostations,
      transmitter,
      transmitterradiostations
    };
  }

  // parseRequestUrl(url: string, requestInfoUtils: RequestInfoUtilities): ParsedRequestUrl {
  //   console.log(url, requestInfoUtils);

  //   if (url === 'api/receiver/radiostation') {

  //   }

  //   return null;
  // }
}
