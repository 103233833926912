<section *ngIf="radioStation" class="row radio-station-list-item">

  <section class="col4">
    <!-- <div class="enabled">
      {{radioStation.enabled}}
    </div> -->

    <div class="name">
      <label>{{radioStation.name}}</label>
    </div>

    <div class="title">
      <label>{{radioStation.title}}</label>
    </div>

    <div class="description">
      <p>{{radioStation.description}}</p>
    </div>
  </section>

</section>
