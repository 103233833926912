import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseModel } from '../model/base-model';
import { LoggerService } from '../../services/logger.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({ 'content-type': 'application/json' })
};

export class CrudeService<T extends BaseModel> {

  constructor(
    protected http: HttpClient,
    protected loggerService: LoggerService,
    protected errorHandler: ErrorHandlerService,
    protected url: string,
    protected serviceName: string) {}

  getAll(): Observable<T[]> {

    return this.http.get<T[]>(this.url).pipe(
      tap(c => this.loggerService.log(this.serviceName, 'fetched all')),
      catchError(this.errorHandler.handleError(this.serviceName, 'getAll', []))
    );
  }

  get(id: number): Observable<T> {

    const url = `${this.url}/${id}`;

    return this.http.get<T>(url).pipe(
      tap(_ => this.loggerService.log(this.serviceName, `fetched entity id=${id}`)),
      catchError(this.errorHandler.handleError<T>(this.serviceName, `get id=${id}`))
    );
  }

  /** PUT: update the entity on the server */
  update(entity: T): Observable<any> {

    return this.http.put(this.url, entity, httpOptions).pipe(
      tap(_ => this.loggerService.log(this.serviceName, `updated entity id=${entity.id}`)),
      catchError(this.errorHandler.handleError<any>(this.serviceName, 'update'))
    );
  }

  /** POST: add a new entity to the server */
  add(entity: T): Observable<T> {
      return this.http.post<T>(this.url, entity, httpOptions).pipe(
        tap((e: T) => this.loggerService.log(this.serviceName, `added entity id=${e.id}`)),
        catchError(this.errorHandler.handleError<T>(this.serviceName, 'add'))
    );
  }

  /** DELETE: delete the entity from the server */
  delete(entity: T | number): Observable<T> {

    const id = typeof entity === 'number' ? entity : entity.id;
    const url = `${this.url}/${id}`;

    return this.http.delete<T>(url, httpOptions).pipe(
      tap(_ => this.loggerService.log(this.serviceName, `deleted entity id=${id}`)),
      catchError(this.errorHandler.handleError<T>(this.serviceName, 'delete'))
    );
  }

  /* GET entities whose name contains search term */
  search(term: string): Observable<T[]> {

    if (!term.trim()) {
      // if not search term, return empty hero array.
      return of([]);
    }

    return this.http.get<T[]>(`${this.url}/?name=${term}`).pipe(
      tap(_ => this.loggerService.log(this.serviceName, `found entities matching "${term}"`)),
      catchError(this.errorHandler.handleError<T[]>(this.serviceName, 'search', []))
    );
  }
}
