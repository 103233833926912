import { Component, OnInit } from '@angular/core';

import { ReceiverRadioStation } from '../../core/models/receiver-radio-station';
import { ReceiverRadioStationService } from '../../core/services/receiver-radio-station.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-receiver-radio-stations',
  templateUrl: './receiver-radio-stations.component.html',
  styleUrls: ['./receiver-radio-stations.component.scss']
})
export class ReceiverRadioStationsComponent extends CrudeListComponent<ReceiverRadioStation> implements OnInit {

  radioStationItemLink = '/my/receiver/radiostations/';

  constructor(private receiverRadioStationService: ReceiverRadioStationService) {
    super(receiverRadioStationService);
  }

  ngOnInit() {
    this.getAll();
  }

}
