import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../app-routing.module';
import { SoundcloudBadgeComponent } from './components/soundcloud-badge/soundcloud-badge.component';
import { GithubBadgeComponent } from './components/github-badge/github-badge.component';
import { OrderedRadioStationItemComponent } from './components/ordered-radio-station-item/ordered-radio-station-item.component';
import { RadioStationListItemComponent } from './components/radio-station-list-item/radio-station-list-item.component';

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule
  ],
  declarations: [
    SoundcloudBadgeComponent,
    GithubBadgeComponent,
    OrderedRadioStationItemComponent,
    RadioStationListItemComponent
    ],
  exports: [
    SoundcloudBadgeComponent,
    GithubBadgeComponent,
    OrderedRadioStationItemComponent
  ]
})
export class SharedModule { }
