<div class="search-component">
  <h4>search</h4>

  <input type="text" #searchBox class="textbox search-box" (keyup)="search(searchBox.value)" />

  <ul class="search-result">
    <li *ngFor="let circuit of entities$ | async" >
      <a routerLink="/circuit/{{circuit.id}}">
        {{circuit.name}}
      </a>
    </li>
  </ul>
</div>
