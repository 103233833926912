import { ReceiverRadioStation } from '../models/receiver-radio-station';

export const RECEIVER_RADIO_STATIONS: ReceiverRadioStation[] = [
    {
        id: 13,
        orderId: 0,
        radioStation: {
            description: 'Experiments and all that jazz..',
            enabled: true,
            id: 13,
            name: 'The First Kube :: Radiostation',
            title: '..dance dance dance dance dance to the radio..',
            version: 0
        },
        version: 0
    },
    {
        id: 9,
        orderId: 1,
        radioStation: {
            description: 'radioStation2',
            enabled: true,
            id: 5,
            name: 'radioStation2',
            title: 'radioStation2',
            version: 0
        },
        version: 0
    },
    {
        id: 21,
        orderId: 2,
        radioStation: {
            description: 'radioStation3',
            enabled: true,
            id: 23,
            name: 'radioStation3',
            title: 'radioStation3',
            version: 0
        },
        version: 0
    },
    {
        id: 11,
        orderId: 3,
        radioStation: {
            description: 'radioStation4',
            enabled: true,
            id: 11,
            name: 'radioStation4',
            title: 'radioStation4',
            version: 0
        },
        version: 0
    },
    {
        id: 10,
        orderId: 4,
        radioStation: {
            description: 'radioStation5',
            enabled: true,
            id: 10,
            name: 'radioStation5',
            title: 'radioStation5',
            version: 0
        },
        version: 0
    },
    {
        id: 1,
        orderId: 5,
        radioStation: {
            description: 'radioStation5',
            enabled: true,
            id: 1,
            name: 'radioStation5',
            title: 'radioStation5',
            version: 0
        },
        version: 0
    },
    {
        id: 2,
        orderId: 6,
        radioStation: {
            description: 'radioStation5',
            enabled: true,
            id: 2,
            name: 'radioStation5',
            title: 'radioStation5',
            version: 0
        },
        version: 0
    },
    {
        id: 3,
        orderId: 7,
        radioStation: {
            description: 'radioStation5',
            enabled: true,
            id: 3,
            name: 'radioStation5',
            title: 'radioStation5',
            version: 0
        },
        version: 0
    }
];
