<h2>Muzak</h2>

<app-soundcloud-badge></app-soundcloud-badge>

<article class="muzaks" *ngFor="let muzak of entities">

  <a name="#{{muzak.name}}"></a><h3>{{muzak.title}}</h3>

  <p>
    <span class="more">Build: <time>{{muzak.date}}</time></span>
    <br />
    <span class="more">Duration: {{muzak.duration}}</span>
    <br />
    <span class="more">Size: {{muzak.size}}</span>
    <br />

    <br />
    <a class="more" routerLink="/muzak/{{muzak.id}}">More</a>
    <a class="more" href="{{muzak.link}}" target="_blank">Download</a>
  </p>
</article>
