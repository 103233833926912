import { Component, OnInit } from '@angular/core';

import { Circuit } from '../../core/models/circuit';
import { CircuitService } from '../../core/services/circuit.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-circuits',
  templateUrl: './circuits.component.html',
  styleUrls: ['./circuits.component.scss']
})
export class CircuitsComponent extends CrudeListComponent<Circuit> implements OnInit {

  constructor(private circuitService: CircuitService) {
    super(circuitService);
  }

  ngOnInit() {
    this.getAll();
  }

  getFirstImage(circuit: Circuit): string {
    if (circuit.images) {
      return circuit.images[0];
    }

    return undefined;
  }

  addNew(name: string): void {

    name = name.trim();

    if (!name) {
      return;
    }

    this.add({ name } as Circuit);
  }
}
