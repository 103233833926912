import { Component, OnInit } from '@angular/core';

import { AuthService } from './../services/auth.service';

import { User } from './../../shared/models/user';
import { AuthResult } from '../../shared/models/auth-result';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: User = {
    username: '',
    password: ''
  };

  constructor(public authService: AuthService) { }

  ngOnInit() {
    // this.authService.logout();
  }

  // onSubmit() {
  //   // this.authService.login(this.model).subscribe();
  //   this.authService.login();
  // }
}
