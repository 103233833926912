import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private loggerService: LoggerService) { }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<K>(sender: string, operation = 'operation', result?: K) {
    return (error: any): Observable<K> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.loggerService.log(sender, `${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as K);
    };
  }
}
