import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Muzak } from '../../core/models/muzak';
import { MuzakService } from '../../core/services/muzak.service';

import { CrudeDetailComponent } from '../../core/base/component/crude-detail.component';

@Component({
  selector: 'app-muzak-detail',
  templateUrl: './muzak-detail.component.html',
  styleUrls: ['./muzak-detail.component.scss']
})
export class MuzakDetailComponent extends CrudeDetailComponent<Muzak> implements OnInit {

  @Input() muzak: Muzak;

  constructor(
    route: ActivatedRoute,
    muzakService: MuzakService,
    location: Location) {

    super(route, muzakService, location);

    this.entity = this.muzak;
  }

  static roles() {
    return {
      expectedRole: 'admin'
    };
  }

  ngOnInit() {
    this.get();
  }

  soudCloudSrc(): string {
    return `http://w.soundcloud.com/player/?
    url=http%3A%2F%2Fapi.soundcloud.com
    %2Ftracks%2F${this.entity.soundcloud}
    &auto_play=false
    &show_artwork=false
    &color=ff0043`;
  }
}
