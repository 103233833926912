import { Observable, Subject } from 'rxjs';

import {
   debounceTime, distinctUntilChanged, switchMap
 } from 'rxjs/operators';

import { BaseModel } from '../model/base-model';
import { CrudeService } from '../service/crude.service';

export class CrudeSearchComponent<T extends BaseModel> {

  entities$: Observable<T[]>;

  private searchTerms = new Subject<string>();

  constructor(private crudeSrvice: CrudeService<T>) { }

  // Push a search term into the observable stream.
  search(term: string): void {
    this.searchTerms.next(term);
  }

  searchInit(): Observable<T[]> {

    return this.searchTerms.pipe(
        // wait 300ms after each keystroke before considering the term
        debounceTime(300),

        // ignore new term if same as previous term
        distinctUntilChanged(),

        // switch to new search observable each time the term changes
        switchMap((term: string) => this.crudeSrvice.search(term)),
      );
  }
}
