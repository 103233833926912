import { Component, OnInit } from '@angular/core';

import { Link } from '../../core/models/link';
import { LinkService } from '../../core/services/link.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent extends CrudeListComponent<Link> implements OnInit {

  groupedLinks: any[];

  constructor(private linkService: LinkService) {
    super(linkService);
  }

  ngOnInit() {
    this.getAll();
  }

  onEntitiesFetched(): void {
    this.groupedLinks = this.groupBy(this.entities, l => l.group);
    console.log('kkkkk', this.entities, this.groupedLinks);

    this.groupedLinks.forEach(e => console.log(e));

  }

  private groupBy(list, keyFunction): any[] {
    const map = [];

    list.forEach(item => {
        const key = keyFunction(item);
        const collection = map.filter(l => l.key === key).map(l => l.value);
        if (!collection) {
          console.log('not found', key);
          map.push({key, value: [item]});
        } else {
          console.log('found', collection);
          collection.push(item);
        }
    });

    return map;
  }
}
