import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';

import { ReceiverRadioStation } from '../../core/models/receiver-radio-station';
import { ReceiverRadioStationService } from '../../core/services/receiver-radio-station.service';

import { CrudeDetailComponent } from '../../core/base/component/crude-detail.component';
import { RadioStation } from 'src/app/shared/models/radio-station';

@Component({
  selector: 'app-receiver-radio-station-detail',
  templateUrl: './receiver-radio-station-detail.component.html',
  styleUrls: ['./receiver-radio-station-detail.component.scss']
})
export class ReceiverRadioStationDetailComponent extends CrudeDetailComponent<ReceiverRadioStation> implements OnInit {

  private receiverRadioStationState: ReceiverRadioStation;
  private radioStationForm: FormGroup;

  constructor(
    route: ActivatedRoute,
    receiverRadioStationService: ReceiverRadioStationService,
    location: Location) {

    super(route, receiverRadioStationService, location);
  }

  ngOnInit() {
    this.setupFormGroup();
    this.setupFormEvents();

    this.get();
  }

  onEntityFetched() {
    if (!this.entity) {
      return;
    }

    this.applyNewStateToForm(this.entity);
  }

  submit() {
    this.applyFormToState();
    console.warn('ReceiverRadioStationDetail', this.receiverRadioStationState);
    this.save();
  }

  private setupFormGroup() {
    this.radioStationForm = new FormGroup({
      name: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      enabled: new FormControl(false)
    });
  }

  private setupFormEvents() {
    this.radioStationForm.get('enabled').valueChanges.subscribe(value => {
    });
  }

  private applyNewStateToForm(receiverRadioStation: ReceiverRadioStation) {
    this.receiverRadioStationState = receiverRadioStation;

    const radioStationState: RadioStation = receiverRadioStation.radioStation;

    this.radioStationForm.get('name').setValue(radioStationState.name);
    this.radioStationForm.get('title').setValue(radioStationState.title);
    this.radioStationForm.get('description').setValue(radioStationState.description);
    this.radioStationForm.get('enabled').setValue(radioStationState.enabled);
  }

  private applyFormToState() {
    const radioStationState: RadioStation = this.entity.radioStation;

    radioStationState.name = this.radioStationForm.get('name').value;
    radioStationState.title = this.radioStationForm.get('title').value;
    radioStationState.description = this.radioStationForm.get('description').value;
    radioStationState.enabled = this.radioStationForm.get('enabled').value;
  }
}
