import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from './logger.service';
import { ErrorHandlerService } from './error-handler.service';
import { CrudeService } from '../base/service/crude.service';

import { ReceiverRadioStation } from '../models/receiver-radio-station';

@Injectable({
  providedIn: 'root'
})
export class ReceiverRadioStationService extends CrudeService<ReceiverRadioStation> {

  constructor(
    http: HttpClient,
    loggerService: LoggerService,
    errorHandler: ErrorHandlerService) {

    super(http, loggerService, errorHandler, 'api/receiverradiostations', 'ReceiverRadioStationService');
  }
}
