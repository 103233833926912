import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Transmitter } from '../../core/models/transmitter';
import { TransmitterService } from '../../core/services/transmitter.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-transmitter',
  templateUrl: './transmitter.component.html',
  styleUrls: ['./transmitter.component.scss']
})
export class TransmitterComponent extends CrudeListComponent<Transmitter> implements OnInit {

  private form: FormGroup;
  private state: Transmitter;

  private isEnabled: boolean;

  constructor(private transmitterService: TransmitterService) {
    super(transmitterService);
  }

  ngOnInit() {
    this.setupFormGroup();
    this.setupFormEvents();

    this.getAll();
  }

  onEntitiesFetched() {
    if (!this.entities.length) {
      return;
    }

    this.applyNewStateToForm(this.entities[0]);
  }

  onEntityAdded(response: Transmitter) {
    this.applyNewStateToForm(response);
  }

  submit() {
    this.applyFormToState();
    console.warn('Transmitter', this.state);
    this.update(this.state);
  }

  private setupFormGroup() {
    this.form = new FormGroup({
      enabled: new FormControl(false)
    });
  }

  private setupFormEvents() {
    this.form.get('enabled').valueChanges.subscribe(value => {
      this.isEnabled = value;
    });
  }

  private applyNewStateToForm(transmitter: Transmitter) {
    this.state = transmitter;

    this.form.get('enabled').setValue(transmitter.enabled);
  }

  private applyFormToState() {
    this.state.enabled = this.form.get('enabled').value;
  }

  private createNewTransmitter() {
    const transmitter: Transmitter = new Transmitter();
    transmitter.id = -1;
    transmitter.enabled = true;
    transmitter.version = 0;

    this.add(transmitter);
  }
}
