import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoggerService } from './logger.service';
import { ErrorHandlerService } from './error-handler.service';
import { CrudeService } from '../base/service/crude.service';

import { Link } from '../models/link';

@Injectable({
  providedIn: 'root'
})
export class LinkService extends CrudeService<Link> {

  constructor(
    http: HttpClient,
    loggerService: LoggerService,
    errorHandler: ErrorHandlerService) {

    super(http, loggerService, errorHandler, 'api/links', 'LinkService');
  }
}
