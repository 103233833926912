import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';

import { AppRoutingModule } from '../app-routing.module';

import { MessagesComponent } from './messages/messages.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AuthService } from './services/auth.service';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { Auth0Service } from './services/auth0.service';

export const emptyTokenGetter = (() => '');

const JWT_MODULE_OPTIONS: JwtModuleOptions = {
    config: {
        tokenGetter: emptyTokenGetter
    }
};

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    JwtModule.forRoot(JWT_MODULE_OPTIONS)
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    MessagesComponent,
    LoginComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MessagesComponent,
    LoginComponent
  ],
  providers: [
    // AuthGuard,
    // RoleGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtInterceptor,
    //   multi: true
    // },
    // {
    //   provide: AuthService,
    //   useClass: Auth0Service
    // }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
      if (parentModule) {
        throw new Error('CoreModule is already loaded. Import only in AppModule');
      }
  }
}
