import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { LoggerService } from './logger.service';
import { ErrorHandlerService } from './error-handler.service';
import { CrudeService } from '../base/service/crude.service';

import { Application } from '../models/application';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends CrudeService<Application> {

  constructor(
    http: HttpClient,
    loggerService: LoggerService,
    errorHandler: ErrorHandlerService) {

    super(http, loggerService, errorHandler, 'api/applications', 'ApplicationService');
  }
}
