import { Component, OnInit } from '@angular/core';

import { TransmitterRadioStation } from '../../core/models/transmitter-radio-station';
import { TransmitterRadioStationService } from '../../core/services/transmitter-radio-station.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-transmitter-radio-stations',
  templateUrl: './transmitter-radio-stations.component.html',
  styleUrls: ['./transmitter-radio-stations.component.scss']
})
export class TransmitterRadioStationsComponent extends CrudeListComponent<TransmitterRadioStation> implements OnInit {

  radioStationItemLink = '/my/transmitter/radiostation/';

  constructor(private transmitterRadioStationService: TransmitterRadioStationService) {
    super(transmitterRadioStationService);
  }

  ngOnInit() {
    this.getAll();
  }

}
