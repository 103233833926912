<h2>Noise</h2>

<article>
  <p>
    source ~
    <a href="http://en.wikipedia.org/wiki/Colors_of_noise" target="_blank">
      http://en.wikipedia.org/wiki/Colors_of_noise
    </a>
    ~
  </p>

  <h4>Colors of noise</h4>

  <p>
  In audio engineering, electronics, physics, and many other fields, the color of a noise signal (a signal produced by a stochastic process) is generally understood to be some broad characteristic of its power spectrum.

  Different "colors" of noise have significantly different properties: for example, as audio signals they will sound differently to human ears, and as images they will have a visibly different texture. Therefore, each application typically requires noise of a specific "color".

  This sense of "color" for noise signals is similar to the concept of timbre in music (which is also called "tone color"); however the latter is almost always used for sound, and may consider very detailed features of the spectrum.

  The practice of naming kinds of noise after colors started with "white noise", a signal whose spectrum has equal power within any equal interval of frequencies. That name was given by analogy with "white light", which was (incorrectly) assumed to have such a "flat" power spectrum over the visible range. Other color names, like "pink", "red", and "blue" were then given to noise with other spectral profiles; often (but not always) in reference to the color of light with similar spectra. Some of those names have standard definitions in certain disciplines, while others are very informal and poorly defined.
  </p>

  <h4>Power-law noise</h4>
  <p>
  Many of these definitions assume a signal with components at all frequencies, with a power spectral density per unit of bandwidth proportional to 1/f β and hence they are examples of power-law noise. For instance, the spectral density of white noise is flat (β = 0), while flicker or pink noise has β = 1, and brown noise has β = 2.
  </p>

  <h4>Technical definitions</h4>
  <p>
  Various noise models are employed in analysis, many of which fall under the above categories. AR noise or "autoregressive noise" is such a model, and generates simple examples of the above noise types, and more. The Federal Standard 1037C Telecommunications Glossary[citation needed] defines white, pink, blue, and black.

  The color names for these different types of sounds are derived from a loose analogy between the spectrum of frequencies of sound wave present in the sound (as shown in the blue diagrams) and the equivalent spectrum of light wave frequencies. That is, if the sound wave pattern of "blue noise" were translated into light waves, the resulting light would be blue, and so on.
  </p>

  <i>White noise</i>
  <p>
  White noise is a signal (or process), named by analogy to white light, with a flat frequency spectrum when plotted as a linear function of frequency (e.g., in Hz). In other words, the signal has equal power in any band of a given bandwidth (power spectral density) when the bandwidth is measured in Hz. For example, with a white noise audio signal, the range of frequencies between 40 Hz and 60 Hz contains the same amount of sound power as the range between 400 Hz and 420 Hz, since both intervals are 20 Hz wide. Note that spectra are often plotted with a logarithmic frequency axis rather than a linear one, in which case equal physical widths on the printed or displayed plot do not all have the same bandwidth, with the same physical width covering more Hz at higher frequencies than at lower frequencies. In this case a white noise spectrum that is equally sampled in the logarithm of frequency (i.e., equally sampled on the X axis) will slope upwards at higher frequencies rather than being flat. However it is not unusual in practice for spectra to be calculated using linearly-spaced frequency samples but plotted on a logarithmic frequency axis, potentially leading to misunderstandings and confusion if the distinction between equally spaced linear frequency samples and equally spaced logarithmic frequency samples is not kept in mind.
  </p>

  <i>Pink noise</i>
  <p>
  The frequency spectrum of pink noise is linear in logarithmic space; it has equal power in bands that are proportionally wide.[1][3] This means that pink noise would have equal power in the frequency range from 40 to 60 Hz as in the band from 4000 to 6000 Hz. Since humans hear in such a proportional space, where a doubling of frequency (an octave) is perceived the same regardless of actual frequency (40–60 Hz is heard as the same interval and distance as 4000–6000 Hz), every octave contains the same amount of energy and thus pink noise is often used as a reference signal in audio engineering. The power density, compared with white noise, decreases by 3 dB per octave (density proportional to 1/f ). For this reason, pink noise is often called "1/f noise".

  Since there are an infinite number of logarithmic bands at both the low frequency (DC) and high frequency ends of the spectrum, any finite energy spectrum must have less energy than pink noise at both ends. Pink noise is the only power-law spectral density that has this property: all steeper power-law spectra are finite if integrated to the high-frequency end, and all flatter power-law spectra are finite if integrated to the DC, low-frequency limit.[citation needed]
  </p>

  <i>Brown(ian) noise</i>
  <p>
  In fields that adopt precise definitions, the terminology "red noise", also called Brown noise or Brownian noise, will usually refer to a power density which decreases 6 dB per octave with increasing frequency (density proportional to 1/f 2)[1] over a frequency range which does not include DC (in a general sense, does not include a constant component, or value at zero frequency). In areas where terminology is used loosely, "red noise" may refer to any system where power density decreases with increasing frequency.[4]

  The first definition can be generated by an algorithm which simulates Brownian motion or by integrating white noise. "Brown" noise is not named for a power spectrum that suggests the colour brown; rather, the name is a corruption of Brownian motion. "Red noise" describes the shape of the power spectrum, with pink being between red and white. Also known as "random walk" or "drunkard's walk" noise.
  </p>

  <i>Blue noise</i>
  <p>
  Blue noise is also called azure noise. Blue noise's power density increases 3 dB per octave with increasing frequency (density proportional to f ) over a finite frequency range.[1][5] In computer graphics, the term "blue noise" is sometimes used more loosely as any noise with minimal low frequency components and no concentrated spikes in energy. This can be good noise for dithering.[6] Retinal cells are arranged in a blue-noise-like pattern which yields good visual resolution.
  </p>


  <i>Violet noise</i>
  <p>
  Violet noise is also called purple noise. Violet noise's power density increases 6 dB per octave with increasing frequency[1][8][9] (density proportional to f 2) over a finite frequency range. It is also known as differentiated white noise, due to its being the result of the differentiation of a white noise signal.

  Acoustic thermal noise of water has a violet spectrum, causing it to dominate hydrophone measurements at high frequencies
  </p>

  <i>Grey noise</i>
  <p>
  Grey noise is random white noise subjected to a psychoacoustic equal loudness curve (such as an inverted A-weighting curve) over a given range of frequencies, giving the listener the perception that it is equally loud at all frequencies.[citation needed] This is in contrast to standard white noise which has equal strength over a linear scale of frequencies but is not perceived as being equally loud due to biases in the human equal-loudness contour.
  <p>

  <h3>
    <a href="https://noise.thefirstkube.net" target="_blank">more noise</a>
  </h3>
</article>
