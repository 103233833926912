<section *ngIf="orderedRadioStation" class="row ordered-radio-station-item">

  <section class="col1">
    <span class="order-number">{{orderedRadioStation.orderId}}</span>
  </section>

  <section class="col2">
    <app-radio-station-list-item
      [radioStation]="orderedRadioStation.radioStation">
    </app-radio-station-list-item>
  </section>

  <section class="col1">
    <a class="more" routerLink="/{{moreRouterLink}}/{{orderedRadioStation.id}}">edit</a>
  </section>

</section>
