import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BaseModel } from '../model/base-model';
import { CrudeService } from '../service/crude.service';

export class CrudeDetailComponent<T extends BaseModel> {

  entity: T;

  constructor(
    private route: ActivatedRoute,
    private crudeSrvice: CrudeService<T>,
    private location: Location
  ) { }

  goBack(): void {
    this.location.back();
  }

  onEntitiyFetched(): void {

  }

  get(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.crudeSrvice.get(id)
      .subscribe(e => {
        this.entity = e;
        this.onEntitiyFetched();
      });
  }

  save(): void {
    this.crudeSrvice.update(this.entity)
      .subscribe(() => this.goBack());
  }
}
