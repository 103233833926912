import { Component, OnInit } from '@angular/core';

import { Muzak } from '../../core/models/muzak';
import { MuzakService } from '../../core/services/muzak.service';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

@Component({
  selector: 'app-muzaks',
  templateUrl: './muzaks.component.html',
  styleUrls: ['./muzaks.component.scss']
})
export class MuzaksComponent extends CrudeListComponent<Muzak> implements OnInit {

  constructor(private muzakService: MuzakService) {
    super(muzakService);
  }

  ngOnInit() {
    this.getAll();
  }
}
