import { Component, Input, OnInit } from '@angular/core';
import { OrderedRadioStation } from '../../models/ordered-radio-station';

@Component({
  selector: 'app-ordered-radio-station-item',
  templateUrl: './ordered-radio-station-item.component.html',
  styleUrls: ['./ordered-radio-station-item.component.scss']
})
export class OrderedRadioStationItemComponent implements OnInit {

  @Input() orderedRadioStation: OrderedRadioStation;
  @Input() moreRouterLink: string;

  constructor() { }

  ngOnInit() {
  }

}
