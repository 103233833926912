import { BaseModel } from '../../core/base/model/base-model';

export class Muzak extends BaseModel {
  name: string;
  title: string;
  description: string;
  link: string;
  soundcloud: string;
  soundcloudLink: string;
  images: string[];
  date: string;
  duration: string;
  size: string;
}
