<div *ngIf="entity">

  <h4>
    {{entity.title}}&nbsp;&nbsp;&nbsp;.:::.&nbsp;&nbsp;&nbsp;[Build: <time>{{entity.date}}</time>]
  </h4>

  <button (click)="goBack()">go back</button>

  <p>
    <span class="more">Build: <time>{{entity.date}}</time></span>
    <br />
    <span class="more">Duration: {{entity.duration}}</span>
    <br />
    <span class="more">Size: {{entity.size}}</span>
    <br />
    <br />
    <a class="more" href="{{entity.link}}" target="_blank">Download</a>
  </p>

  <article class="muzak-img" *ngFor="let img of entity.images">
    <a href="{{img}}" target="_blank">
      <img src="{{img}}" alt="{{entity.title}}" width="100" height="100" />
    </a>
  </article>

  <p>{{entity.description}}</p>


  <div *ngIf="entity.soundcloud">
    <!-- <iframe
      allowtransparency="true"
      scrolling="no"
      frameborder="no"
      src="https://w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F{{entity.soundcloud}}&amp;
            auto_play=false&amp;show_artwork=false&amp;color=ff0043"
      style="width: 100%; height: 166px;">
    </iframe> -->

    <!-- <iframe
      width="100%"
      height="300"
      scrolling="no"
      frameborder="no"
      allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/122618771&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
    </iframe> -->

    <div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
      <a
        href="https://soundcloud.com/thefirstkube"
        title="TheFirstKube"
        target="_blank"
        style="color: #cccccc; text-decoration: none;">
        TheFirstKube
      </a> ·
      <a
        href="{{entity.soundcloudLink}}"
        title="{{entity.description}}"
        target="_blank"
        style="color: #cccccc; text-decoration: none;">
        {{entity.description}}
      </a>
    </div>

  </div>

</div>
