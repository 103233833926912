import { Application } from '../models/application';

export const APPLICATIONS: Application[] = [
  {
    id: 4,
    name: 'NoiseRadio',
    title: 'NoiseRadio',
    summary: 'A HTML5 audio player wrapper, suitable for WebRadio streams and more...',
    description: '',
    image: '',
    date: '16-11-2021',
    version: '2.0.0',
    source: 'https://github.com/stolosapo/NoiseRadio',
    sourceType: 'github'
  },

  {
    id: 3,
    name: 'NoiseModule',
    title: 'NoiseModule',
    summary: 'A JQuery library for playing with Web Audio API...',
    description: '',
    image: '',
    date: '05-07-2016',
    version: '1.0.0',
    source: 'https://github.com/stolosapo/NoiseModule',
    sourceType: 'github'
  },

  {
    id: 2,
    name: 'CppPlayground',
    title: 'CppPlayground',
    summary: 'A playground for C++ applications and experiments...'
    + ' Currently implements an Icecast Streaming Server client, for streaming .mp3 tracks',
    description: '',
    image: '',
    date: '20-05-2015',
    version: '1.0.0',
    source: 'https://github.com/stolosapo/CppPlayground',
    sourceType: 'github'
  },

  {
    id: 1,
    name: 'ClassManager',
    title: 'ClassManager',
    summary: 'Application that creates .NET (VB or C#) classes based on a given MS SQL database',
    description: '',
    image: 'assets/img/apps/ClassManager.png',
    date: '19-12-2012',
    version: '1.0.0.5',
    source: 'http://www.mediafire.com/download.php?h5c48274mdlcy82',
    sourceType: 'download'
  },

  {
    id: 0,
    name: 'Sudoku',
    title: 'Sudoku',
    summary: 'Application that solves Sudoku puzzles.',
    description: '',
    image: 'assets/img/apps/Sudoku.png',
    date: '5-7-2012',
    version: '1.0.0.1',
    source: 'http://www.mediafire.com/download.php?095j95uymp37214',
    sourceType: 'download'
  }
];
