import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auth0Service } from './auth0.service';

@Injectable()
export abstract class AuthService {

  abstract login(redirectPath: string): void;

  abstract logout(): void;

  abstract getToken$(): Observable<string>;

  abstract isAuthenticated$(): Observable<boolean>;

}
