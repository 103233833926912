<h2>
  transmitter
</h2>

<section *ngIf="!entities || !entities.length">
  <button (click)="createNewTransmitter()">Create Transmitter</button>
</section>

<section class="transmitter" *ngIf="entities && entities.length">

  <form [formGroup]="form" (ngSubmit)="submit()">

    <span>Enabled</span>
    <input type="checkbox" formControlName="enabled">

    <section *ngIf="isEnabled">
      <a routerLink="/my/transmitter/radiostations">radio stations</a>
      <a routerLink="/my/transmitter/playlist">playlists</a>
    </section>

    <button type="submit" [disabled]="!form.valid">Submit</button>
  </form>

</section>