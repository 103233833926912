<div *ngIf="entity">

  <h4>
    {{entity.title}}&nbsp;&nbsp;&nbsp;.:::.&nbsp;&nbsp;&nbsp;[Build: <time>{{entity.date}}</time>]
  </h4>

  <button (click)="goBack()">go back</button>

  <article class="circuit-img" *ngFor="let img of entity.images">
    <a href="{{img}}" target="_blank">
      <img src="{{img}}" alt="{{entity.title}}" width="100" height="100" />
    </a>
  </article>

  <p>{{entity.description}}</p>

  <article class="circuit-file" *ngFor="let file of entity.files">
    <a href="{{file}}" target="_blank" >{{file}}</a>
  </article>

  <article class="circuit-sch" *ngFor="let sch of entity.schematics">
    <a href="{{sch}}" target="_blank">
      <img src="{{sch}}" alt="{{entity.title}}" width="100" height="100" />
    </a>
  </article>

</div>
