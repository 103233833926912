<h2>Login</h2>

<!-- <a routerLink="profile" *ngIf="authService.loggedIn">Profile</a> -->

<!-- <header>
  <button (click)="authService.login()" *ngIf="!authService.loggedIn">Log In</button>
  <button (click)="authService.logout()" *ngIf="authService.loggedIn">Log Out</button>
</header> -->

<!-- <div class="row form login">
  <div class="col2">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <div class="row form-group">
        <div class="col1">
          <label for="username">Username</label>
        </div>
        <div class="col2">
          <input type="text" class="form-control" id="username"
                 required
                 [(ngModel)]="model.username" name="username"
                 #username="ngModel">
        </div>
        <div class="col1 alert alert-danger" [hidden]="username.valid || username.pristine">
          Username is required
        </div>
      </div>

      <div class="row form-group">
        <div class="col1">
          <label for="password">Password</label>
        </div>
        <div class="col2">
          <input type="password" class="form-control" id="password"
                 required
                 [(ngModel)]="model.password" name="password"
                 #password="ngModel">
        </div>
        <div class="col1 alert alert-danger" [hidden]="password.valid || password.pristine">
          Password is required
        </div>
      </div>

      <button type="submit" class="btn btn-success" [disabled]="!loginForm.form.valid">Login</button>
    </form>
  </div>
</div> -->
