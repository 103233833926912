import { BaseModel } from '../model/base-model';
import { CrudeService } from '../service/crude.service';

export class CrudeListComponent<T extends BaseModel> {

  entities: T[];

  constructor(private crudeService: CrudeService<T>) { }

  onEntitiesFetched(): void {

  }

  onEntityAdded(response: T) {

  }

  onEntityUpdated(response: any) {

  }

  getAll(): void {
    this.crudeService.getAll()
      .subscribe(e => {
        this.entities = e;
        this.onEntitiesFetched();
      });
  }

  add(entity: T): void {
    if (!entity) {
      return;
    }

    this.crudeService.add(entity)
      .subscribe(e => {
        this.entities.push(e);
        this.onEntityAdded(e);
      });
  }

  update(entity: T): void {
    this.crudeService.update(entity)
      .subscribe(e => {
        this.onEntityUpdated(e);
      });
  }

  delete(entity: T): void {
    this.entities = this.entities.filter(c => c !== entity);
    this.crudeService.delete(entity).subscribe();
  }
}
