<h2>
  receiver
</h2>

<section *ngIf="!entities || !entities.length">
  <button (click)="createNewReceiver()">Create Receiver</button>
</section>

<section class="receiver" *ngIf="entities && entities.length">

  <form [formGroup]="receiverForm" (ngSubmit)="submit()">

    <span>Enabled</span>
    <input type="checkbox" formControlName="enabled">

    <section *ngIf="isEnabled">
      <a routerLink="/my/receiver/radiostations">radio stations</a>
    </section>

    <button type="submit" [disabled]="!receiverForm.valid">Submit</button>
  </form>

</section>