import { Component, OnInit } from '@angular/core';

import { CrudeListComponent } from '../../core/base/component/crude-list.component';

import { Picture } from '../../core/models/picture';
import { DashboardPictureService } from '../../core/services/dashboard-picture.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends CrudeListComponent<Picture> implements OnInit  {

  randomPicture: Picture = new Picture();

  constructor(private dashboardPictureService: DashboardPictureService) {
    super(dashboardPictureService);
  }

  ngOnInit() {
    this.getAll();
  }

  onEntitiesFetched(): void {
    const randomIndex: number = this.getRandomPictureIndex();
    this.randomPicture = this.entities[randomIndex];
  }

  private getRandomPictureIndex(): number {
    const len = this.entities.length;
    if (len <= 0) {
      return -1;
    }

    return Math.floor((Math.random() * len));
  }

}
