<section class="container">

  <header class="component header">
    <app-header [title]="title"></app-header>
  </header>

  <nav class="component menu">
    <!-- <a class="menu-item" routerLink="/my">my</a> -->
    <a class="menu-item" routerLink="/circuits">circuits</a>
    <a class="menu-item" routerLink="/muzaks">muzaks</a>
    <a class="menu-item" routerLink="/applications">apps</a>
    <a class="menu-item" href="https://radio.thefirstkube.net/" target="_blank">radio</a>
    <a class="menu-item" routerLink="/noise">noise</a>
    <a class="menu-item" href="https://thefirstkube.blogspot.com/" target="_blank">blog</a>
    <!-- <a class="menu-item" routerLink="/links">links</a> -->
    <a class="menu-item" routerLink="/contact">contact</a>
  </nav>

  <main class="component main">
    <router-outlet></router-outlet>
  </main>

  <footer class="component footer">
    <app-footer></app-footer>
  </footer>

  <!-- <section class="component messages">
    <app-messages></app-messages>
  </section> -->

</section>
