import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './core/login/login.component';
import { ProfileComponent } from './feature/profile/profile.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { CircuitsComponent } from './feature/circuits/circuits.component';
import { CircuitDetailComponent } from './feature/circuit-detail/circuit-detail.component';
import { MuzaksComponent } from './feature/muzaks/muzaks.component';
import { MuzakDetailComponent } from './feature/muzak-detail/muzak-detail.component';
import { ApplicationsComponent } from './feature/applications/applications.component';
import { NoiseComponent } from './feature/noise/noise.component';
import { LinksComponent } from './feature/links/links.component';
import { ContactComponent } from './feature/contact/contact.component';
import { MyComponent } from './feature/my/my.component';
import { ReceiverRadioStationsComponent } from './feature/receiver-radio-stations/receiver-radio-stations.component';
import { ReceiverRadioStationDetailComponent } from './feature/receiver-radio-station-detail/receiver-radio-station-detail.component';
import { TransmitterRadioStationsComponent } from './feature/transmitter-radio-stations/transmitter-radio-stations.component';

import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  // { path: 'my', component: MyComponent/*, canActivate: [AuthGuard]*/ },
  // { path: 'my/receiver/radiostations', component: ReceiverRadioStationsComponent/*, canActivate: [AuthGuard]*/ },
  // { path: 'my/receiver/radiostations/:id', component: ReceiverRadioStationDetailComponent/*, canActivate: [AuthGuard]*/ },
  // { path: 'my/transmitter/radiostations', component: TransmitterRadioStationsComponent/*, canActivate: [AuthGuard]*/ },
  // { path: 'login', component: LoginComponent },
  // { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'circuits', component: CircuitsComponent },
  { path: 'circuit/:id', component: CircuitDetailComponent/*, canActivate: [AuthGuard]*/ },
  { path: 'muzaks', component: MuzaksComponent },
  { path: 'muzak/:id', component: MuzakDetailComponent/*, canActivate: [RoleGuard], data: MuzakDetailComponent.roles()*/ },
  { path: 'applications', component: ApplicationsComponent },
  { path: 'noise', component: NoiseComponent },
  { path: 'links', component: LinksComponent },
  { path: 'contact', component: ContactComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
