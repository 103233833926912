import { Link } from '../models/link';

export const LINKS: Link[] = [
  {
    id: 0,
    name: 'Accion Mutante Collective',
    title: 'Accion Mutante Collective',
    href: 'http://accionmutantecollective.blogspot.gr/',
    group: 'Politics',
    enabled: true
  },

  {
     id: 1,
     name: 'Αυτονομο Στεκι Καβάλας',
     title: 'Αυτονομο Στεκι Καβάλας',
     href: 'http://anarxikoikavalas.squat.gr/',
     group: 'Politics',
     enabled: false
   },

   {
      id: 13,
      name: 'Καταληψη Βυρωνος 3',
      title: 'Καταληψη Βυρωνος 3',
      href: 'http://katalipsi-virwnos-3.espivblogs.net/',
      group: 'Politics',
      enabled: false
    },

    /* LABELS */
    {
      id: 12,
      name: 'Eye5 Records',
      title: 'Eye5 Records',
      href: 'http://www.eye5records.gr/index.php',
      group: 'Labels',
      enabled: false
    },

    {
      id: 117,
      name: 'Electric Knife Records',
      title: 'Electric Knife Records',
      href: 'http://www.electrickniferecords.co.uk/',
      group: 'Labels',
      enabled: true
    },

    /* PUNK-ROCK */
     {
       id: 2,
       name: 'Same Old Madness',
       title: 'Same Old Madness',
       href: 'http://sameoldmadness.blogspot.gr/',
       group: 'Punk-Rock',
       enabled: true
     },

     {
       id: 3,
       name: '21179',
       title: '21179',
       href: 'http://www.myspace.com/alteregorajahtaa',
       group: 'Punk-Rock',
       enabled: false
     },

     {
       id: 4,
       name: 'Rajahtaa',
       title: 'Rajahtaa',
       href: 'http://www.myspace.com/rajahtaa',
       group: 'Punk-Rock',
       enabled: false
     },

     {
       id: 5,
       name: 'Ασύμμετρη Απειλή',
       title: 'Ασύμμετρη Απειλή',
       href: 'http://www.myspace.com/asymmetryapeilh',
       group: 'Punk-Rock',
       enabled: false
     },

     /* NOISE */
      {
        id: 6,
        name: 'Tribute to Koji Tano',
        title: 'Tribute to Koji Tano',
        href: 'http://www.steinklang-records.at/koji-tano.html',
        group: 'Noise',
        enabled: false
      },

      {
        id: 7,
        name: 'Merzbow',
        title: 'Merzbow',
        href: 'http://www.merzbow.net/',
        group: 'Noise',
        enabled: true
      },

      {
        id: 8,
        name: 'Masonna',
        title: 'Masonna',
        href: 'http://masonna.main.jp/',
        group: 'Noise',
        enabled: true
      },

      {
        id: 9,
        name: 'Coil',
        title: 'Coil',
        href: 'http://www.thresholdhouse.com/',
        group: 'Noise',
        enabled: false
      },

      {
        id: 10,
        name: 'Einsturzende Neubauten',
        title: 'Einsturzende Neubauten',
        href: 'http://www.neubauten.org/',
        group: 'Noise',
        enabled: true
      },

      {
        id: 11,
        name: 'Throbbing Gristle',
        title: 'Throbbing Gristle',
        href: 'http://www.throbbing-gristle.com/',
        group: 'Noise',
        enabled: true
      },

      {
        id: 18,
        name: 'Strotter Inst.',
        title: 'Strotter Inst.',
        href: 'http://www.strotter.org/ch_news/index.html',
        group: 'Noise',
        enabled: true
      },

      /* RADIO */
       {
         id: 15,
         name: 'XWave Radio',
         title: 'XWave Radio',
         href: 'http://www.xwaveradio.com/',
         group: 'Radio',
         enabled: true
       },

       {
         id: 16,
         name: 'Soma FM',
         title: 'Soma FM',
         href: 'http://somafm.com/',
         group: 'Radio',
         enabled: true
       },

       {
         id: 17,
         name: 'Right On Scales',
         title: 'Right On Scales',
         href: 'http://s1.myfreenetradio.com/ros',
         group: 'Radio',
         enabled: false
       }
];
